import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Img from 'images/icon-hamburger.svg';

const StyledImg = styled(Img)`
  width: inherit;
  height: inherit;
  transform-box: border-box;

  rect {
    width: 100%;
    transition: fill 0.25s ease, transform 0.25s ease;

    &:nth-child(1) {
      top: 0;
      transform-origin: top left;
    }

    &:nth-child(3) {
      width: 65%;
      transform-origin: bottom left;
    }

    body.dark & {
      fill: ${(props) => props.theme.dark.colors.text};
    }

    body.light & {
      fill: ${(props) => props.theme.light.colors.text};
    }
  }

  ${(props) =>
    props.open
      ? `
    rect:nth-child(1) {
      transform: rotate(45deg) translateX(20%) translateY(-25%);
    }

    rect:nth-child(2) {
      display: none;
    }

    rect:nth-child(3) {
      transform: rotate(-45deg) translateX(23%) translateY(26%);
      width: 100%;
    }
  `
      : null}
`;

const Hamburger = ({ open }) => <StyledImg open={open ? 1 : 0} />;

Hamburger.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default Hamburger;
