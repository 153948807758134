import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import { IconContext } from 'react-icons';
import { MdKeyboardArrowDown } from 'react-icons/md';

import { breakpoints } from 'lib/constants';
import { resetSelect } from 'lib/mixins';
import { pxToRem, svgComponentToDataURI, getLinkThemeColor } from 'lib/utils';
import { PoligraficiContext } from 'templates/PostTemplate';

const StyledNav = styled.nav`
  font-size: ${pxToRem('14px')};

  @media screen and (max-width: ${pxToRem(breakpoints.large)}) {
    margin-bottom: ${pxToRem('40px')};
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    flex: 0 0 25%;
    padding-right: ${pxToRem('25px')};
  }
`;

const SelectArrow = ({ color }) => (
  <IconContext.Provider value={{ color }}>
    <MdKeyboardArrowDown />
  </IconContext.Provider>
);

const StyledSelect = styled.select`
  ${resetSelect()}

  padding: ${pxToRem('12px')} ${pxToRem('25px')} ${pxToRem('12px')} ${pxToRem('12px')};
  font-size: inherit;
  background-repeat: no-repeat;
  background-position: right ${pxToRem('8px')} top 50%;
  border: 1px solid;

  body.dark & {
    color: ${(props) => props.theme.dark.colors.text};
    ${(props) =>
      `background-image: url("${svgComponentToDataURI(
        <SelectArrow color={props.theme.dark.colors.textOff} />,
      )}")`};
    border-color: ${(props) => props.theme.dark.colors.separator};
  }

  body.light & {
    color: ${(props) => props.theme.light.colors.text};
    ${(props) =>
      `background-image: url("${svgComponentToDataURI(
        <SelectArrow color={props.theme.light.colors.textOff} />,
      )}")`};
    border-color: ${(props) => props.theme.light.colors.separator};
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    display: none;
  }
`;

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid;

  body.dark & {
    border-top-color: ${(props) => props.theme.dark.colors.separator};
  }

  body.light & {
    border-top-color: ${(props) => props.theme.light.colors.separator};
  }

  @media screen and (max-width: ${pxToRem(breakpoints.large)}) {
    display: none;
  }
`;

const StyledListItem = styled.li`
  border-bottom: 1px solid;

  body.dark & {
    border-bottom-color: ${(props) => props.theme.dark.colors.separator};
  }

  body.light & {
    border-bottom-color: ${(props) => props.theme.light.colors.separator};
  }
`;

const StyledLink = styled(Link)`
  display: block;
  padding: ${pxToRem('14px')} 0;
  text-decoration: none;

  body.dark & {
    color: ${(props) => props.theme.dark.colors.textOff};

    &.isSelected {
      color: ${(props) => props.theme.dark.colors.text};
    }
  }

  body.light & {
    color: ${(props) => props.theme.light.colors.textOff};

    &.isSelected {
      color: ${(props) => props.theme.light.colors.text};
    }
  }

  &:hover {
    body.dark &:not(.isSelected) {
      color: ${(props) => getLinkThemeColor(props.isPoligrafici)};
    }

    body.light &:not(.isSelected) {
      color: ${(props) => getLinkThemeColor(props.isPoligrafici)};
    }
  }

  &.isSelected {
    cursor: default;
    font-weight: 700;
  }
`;

const createDomFrom = (metapageSlug, node, type = 'select') =>
  node.map(({ _id, url, title, metapageResolved = { url } }, i) => {
    const completeUrl = `/${metapageSlug}/${url.current}/` || '#';
    const isPoligrafici = useContext(PoligraficiContext);

    const isHtmlSelect = type === 'select';
    const HtmlSelectOption = (
      <option key={`${_id}-${i}`} value={completeUrl}>
        {title}
      </option>
    );
    const styledListItem = (
      <StyledListItem key={`${_id}-${i}`}>
        <StyledLink
          to={completeUrl}
          title={title}
          activeClassName="isSelected"
          isPoligrafici={isPoligrafici}
        >
          {title}
        </StyledLink>
      </StyledListItem>
    );
    return isHtmlSelect ? HtmlSelectOption : styledListItem;
  });

const ContextNav = ({ metapageSlug, data, uri }) => {
  const [url, setUrl] = useState();
  useEffect(() => {
    setUrl(uri);
  }, []);

  return (
    <StyledNav>
      <StyledSelect
        onChange={(event) => navigate(event.target.value)}
        defaultValue={url}
        value={url}
      >
        {createDomFrom(metapageSlug, data)}
      </StyledSelect>
      <StyledList>{createDomFrom(metapageSlug, data, 'ul')}</StyledList>
    </StyledNav>
  );
};

ContextNav.propTypes = {
  data: PropTypes.array.isRequired,
  uri: PropTypes.string.isRequired,
};

export default ContextNav;
