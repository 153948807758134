import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { breakpoints } from 'lib/constants';
import { pxToRem } from 'lib/utils';

const StyledFluidContent = styled.article`
  font-size: ${pxToRem('14px')};

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    flex-grow: 1;
    padding-left: ${pxToRem('25px')};
  }
`;

const FluidContent = ({ children }) => {
  return <StyledFluidContent>{children}</StyledFluidContent>;
};

FluidContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FluidContent;
