import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { pxToRem, getLinkThemeColor } from 'lib/utils';
import { breakpoints } from 'lib/constants';
import { PoligraficiContext } from 'templates/PostTemplate';

const StyledHeading = styled.h3`
  margin: ${pxToRem('40px')} 0 0 0;
  font-weight: 400;
  line-height: 1.3;
  text-transform: uppercase;
  transition: color 0.5s ease;

  ${(props) => (!props.as || props.as === 'h3') && `font-size: ${pxToRem('15px')};`}
  ${(props) => props.as === 'h4' && `font-size: ${pxToRem('14px')};`}
  ${(props) => props.as === 'h5' && `font-size: ${pxToRem('13px')};`}
  ${(props) => props.as === 'h6' && `font-size: ${pxToRem('12px')};`}

  body.dark & {
    color: ${(props) => getLinkThemeColor(props.isPoligrafici)}
  }

  body.light & {
    color: ${(props) => getLinkThemeColor(props.isPoligrafici)}
  }

  @media screen and (max-width: ${pxToRem(breakpoints.large)}) {
    letter-spacing: ${pxToRem('2px')};
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    letter-spacing: ${pxToRem('3px')};
  }
`;

const SectionHeading = ({ children, headingType }) => {
  const isPoligrafici = useContext(PoligraficiContext);

  return (
    <StyledHeading as={headingType} isPoligrafici={isPoligrafici}>
      {children}
    </StyledHeading>
  );
};

SectionHeading.propTypes = {
  children: PropTypes.node.isRequired,
  headingType: PropTypes.string,
};

export default SectionHeading;
