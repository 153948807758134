import BaseBlockContent from '@sanity/block-content-to-react';
import React from 'react';

import Attachment from 'components/Attachment';
import AttachmentList from 'components/AttachmentList';
import Figure from 'components/Figure';
import SectionHeading from 'components/SectionHeading';
import Table from 'components/Table';
import ComponentList from 'components/ComponentList';
import SimpleParagraph from 'components/SimpleParagraph';

const list = (props) => <ul className="wysiwyg">{props.children}</ul>;

const targetAndClassName = ({ mark, children }) =>
  mark.blank ? (
    <a
      href={mark.href}
      target="_blank"
      rel="noopener noreferrer"
      className={mark.poligrafici ? 'poligrafici' : ''}
    >
      {children}
    </a>
  ) : (
    <a href={mark.href} className={mark.poligrafici ? 'poligrafici' : ''}>
      {children}
    </a>
  );

const marks = { link: targetAndClassName };

const types = {
  table(props) {
    return <Table data={props.node} />;
  },
  block(props) {
    switch (props.node.style) {
      case 'h3':
        return <SectionHeading headingType="h3">{props.children}</SectionHeading>;

      case 'h4':
        return <SectionHeading headingType="h4">{props.children}</SectionHeading>;

      case 'h5':
        return <SectionHeading headingType="h5">{props.children}</SectionHeading>;

      case 'h6':
        return <SectionHeading headingType="h6">{props.children}</SectionHeading>;

      case 'h3verde':
        return (
          <SectionHeading headingType="h3" isGreen>
            {props.children}
          </SectionHeading>
        );

      case 'h4verde':
        return (
          <SectionHeading headingType="h4" isGreen>
            {props.children}
          </SectionHeading>
        );

      case 'h5verde':
        return (
          <SectionHeading headingType="h5" isGreen>
            {props.children}
          </SectionHeading>
        );

      case 'h6verde':
        return (
          <SectionHeading headingType="h6" isGreen>
            {props.children}
          </SectionHeading>
        );

      case 'blockquote':
        return <blockquote>{props.children}</blockquote>;

      default:
        return <p className="wysiwyg">{props.children}</p>;
    }
  },
  figure(props) {
    return <Figure {...props.node} />;
  },
  attachment(props) {
    return <Attachment {...props.node} />;
  },
  attachmentList(props) {
    return <AttachmentList data={props.node.attachmentList} />;
  },
  componentList(props) {
    return <ComponentList data={props.node.components} />;
  },
  simpleParagraph(props) {
    return <SimpleParagraph data={props.node} />;
  },
};

const serializers = {
  list,
  marks,
  types,
};

const BlockContent = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />;

export default BlockContent;
