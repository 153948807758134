import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';

import { addOutline, resetButton } from 'lib/mixins';
import { pxToRem, getLinkThemeColor } from 'lib/utils';
import { PoligraficiContext } from 'templates/PostTemplate';

const StyledButton = styled.button`
  ${resetButton()};
  cursor: pointer;
  font-size: ${pxToRem('12px')};
  background: transparent;
  transition: color 0.5s ease;

  body.dark & {
    color: ${(props) => getLinkThemeColor(props.isPoligrafici)};
  }

  body.light & {
    color: ${(props) => getLinkThemeColor(props.isPoligrafici)};
  }

  &:focus {
    ${addOutline()}
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ToggleTheme = () => {
  const isPoligrafici = useContext(PoligraficiContext);

  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => {
        // Don't render anything at compile time. Deferring rendering until we
        // know which theme to use on the client avoids incorrect initial
        // state being displayed.
        if (theme == null) return null;

        return (
          <StyledButton
            isPoligrafici={isPoligrafici}
            onClick={(e) => toggleTheme(theme === 'dark' ? `light` : `dark`)}
          >
            Dark theme {theme === 'dark' ? `on` : `off`}
          </StyledButton>
        );
      }}
    </ThemeToggler>
  );
};

export default ToggleTheme;
