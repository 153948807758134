import ReactDOMServer from 'react-dom/server';

export const isNumber = (value) =>
  typeof value === 'number' && !Number.isNaN(value) && value !== Infinity && value !== -Infinity;
export const isString = (value) => Object.prototype.toString.call(value) === '[object String]';
export const isURI = (string) => {
  const res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g,
  );
  return res !== null;
};
export const capitalize = (str) =>
  str.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
export function objectToQueryString(obj) {
  const queryString = [];

  Object.keys(obj).forEach((key) => {
    queryString.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  });

  return queryString.join('&');
}
export const pxToRem = (target, base = 16) => {
  if (isNumber(target)) {
    return `${parseInt(target) / base}rem`;
  } else if (isString(target) && target.endsWith('px')) {
    return `${parseInt(target.slice(0, -2)) / base}rem`;
  } else {
    throw new Error('Argument passed in is not a number nor a pixel value');
  }
};
export const hexToRgb = (hex) => {
  return hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))
    .join(', ');
};
export const svgComponentToDataURI = (component) => {
  return 'data:image/svg+xml,' + escape(ReactDOMServer.renderToStaticMarkup(component));
};

export const formatDate = (str) =>
  str && str !== '-'
    ? new Date(str)
        .toLocaleString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .split(',')[0]
    : '-';

export const getThemeColor = (theme, color) => (props) => props.theme[theme].colors[color];

export const getLightThemeColor = (color) => (props) => getThemeColor('light', color)(props);
export const getDarkThemeColor = (color) => (props) => getThemeColor('dark', color)(props);

export const getLinkThemeColor = (isPoligrafici) => (props) =>
  getLightThemeColor(isPoligrafici ? 'poligraficiGreen' : 'link')(props);
