import React, { useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import FocusLock from 'react-focus-lock';

import NavToggler from 'components/NavToggler';
import Logo from 'components/Logo';
import MainMenuMonrif from 'components/MainMenuMonrif';
import MainMenuPoligrafici from 'components/MainMenuPoligrafici';
import { useOnClickOutside } from 'hooks';
import { breakpoints, layers, sizes } from 'lib/constants';
import { pxToRem } from 'lib/utils';
import { PoligraficiContext } from 'templates/PostTemplate';

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${layers.header};
  height: ${pxToRem(sizes.headerHeight)};
  transition: background-color 0.5s ease;

  body.dark & {
    background-color: ${(props) => props.theme.dark.colors.background};
  }

  body.light & {
    background-color: ${(props) => props.theme.light.colors.background};
  }
`;

const StyledHeaderInner = styled.div`
  display: flex;
  align-items: center;
`;

const StyledMenuWrapper = styled.nav`
  margin-left: auto;
  margin-right: ${pxToRem('10px')};
`;

const StyledNavToggler = styled(NavToggler)`
  display: flex;

  @media screen and (min-width: ${breakpoints.large}) {
    display: none;
  }
`;

const Header = ({ uri }) => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = 'main-menu';
  const isPoligrafici = useContext(PoligraficiContext);

  useOnClickOutside(node, () => setOpen(false));

  return (
    <StyledHeader>
      <StyledHeaderInner>
        <Logo uri={uri} />
        <StyledMenuWrapper ref={node}>
          <FocusLock disabled={!open}>
            {isPoligrafici ? (
              <MainMenuPoligrafici open={open} setOpen={setOpen} id={menuId} uri={uri} />
            ) : (
              <MainMenuMonrif open={open} setOpen={setOpen} id={menuId} uri={uri} />
            )}
            <StyledNavToggler open={open} setOpen={setOpen} aria-controls={menuId} />
          </FocusLock>
        </StyledMenuWrapper>
      </StyledHeaderInner>
    </StyledHeader>
  );
};

export default Header;
