import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { breakpoints } from 'lib/constants';
import { pxToRem } from 'lib/utils';

const StyledHeader = styled.header`
  padding-bottom: ${pxToRem('30px')};
  border-bottom: ${pxToRem('1px')} solid;

  body.dark & {
    border-bottom-color: ${(props) => props.theme.dark.colors.separator};
  }

  body.light & {
    border-bottom-color: ${(props) => props.theme.light.colors.separator};
  }

  @media screen and (max-width: ${pxToRem(breakpoints.large)}) {
    margin-top: ${pxToRem('30px')};
    margin-bottom: ${pxToRem('30px')};
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    margin-top: ${pxToRem('60px')};
    margin-bottom: ${pxToRem('40px')};
  }
`;

const StyledTitle = styled.h1`
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;

  @media screen and (max-width: ${pxToRem(breakpoints.large)}) {
    font-size: ${pxToRem('26px')};
    letter-spacing: ${pxToRem('2px')};
    text-align: center;
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    font-size: ${pxToRem('34px')};
    letter-spacing: ${pxToRem('3px')};
  }
`;

const PageHeader = ({ headingType, children, title }) => (
  <StyledHeader>
    <StyledTitle as={headingType}>{title}</StyledTitle>
    {children && <div>{children}</div>}
  </StyledHeader>
);

PageHeader.propTypes = {
  headingType: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

export default PageHeader;
