import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { addOutline, resetButton } from 'lib/mixins';
import { pxToRem, getLinkThemeColor } from 'lib/utils';
import { PoligraficiContext } from 'templates/PostTemplate';

const StyledButton = styled.button`
  ${resetButton()};
  padding: ${pxToRem('15px')} ${pxToRem('20px')};
  color: ${(props) => props.theme.dark.colors.text};
  font-size: ${pxToRem('14px')};
  font-weight: 700;
  border-radius: ${pxToRem('900px')};
  transition: color 0.5s ease, background-color 0.5s ease;
  cursor: pointer;

  body.dark & {
    color: ${(props) => props.theme.dark.colors.text};
    background-color: ${(props) => getLinkThemeColor(props.isPoligrafici)};
  }

  body.light & {
    color: ${(props) => props.theme.light.colors.text};
    background-color: ${(props) => getLinkThemeColor(props.isPoligrafici)};
  }

  ${(props) =>
    props.isHighlighted
      ? `background-color: ${props.theme.dark.colors.link};`
      : `background-color: ${props.theme.dark.colors.textOff};`}

  &:focus {
    ${addOutline()}
  }
`;

const Button = ({ children, isHighlighted, onClick }) => {
  const isPoligrafici = useContext(PoligraficiContext);
  return (
    <StyledButton isHighlighted={isHighlighted} onClick={onClick} isPoligrafici={isPoligrafici}>
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  isHighlighted: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Button;
