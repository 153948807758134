import React from 'react';

const DownloadDisclaimerTipo2 = () => (
  <div style={{ width: '100%', display: 'flex' }}>
    <div className="it" style={{ width: '50%', padding: 15 }}>
      <p>
        Con riferimento all’offerta pubblica di acquisto volontaria (l’“<strong>Offerta</strong>”)
        promossa da Monti Riffeser S.r.l. (“<strong>Monti Riffeser</strong>” o l’“
        <strong>Offerente</strong>”) sulla totalità delle azioni ordinarie di Monrif S.p.A.
        (rispettivamente le “<strong>Azioni</strong>” e l’“<strong>Emittente</strong>”), dedotte le
        Azioni già detenute dall’Offerente e dalle persone che agiscono di concerto con il medesimo,
        nonché le Azioni proprie detenute
        <br />
        dall’Emittente, si precisa che l’Offerta è rivolta a parità di condizioni a tutti i titolari
        delle Azioni, ma è promossa esclusivamente sul mercato italiano, in quanto le Azioni sono
        negoziate unicamente su Euronext Milan, organizzato e gestito da Borsa Italiana S.p.A.
      </p>
      <p>
        L’Offerta non è stata e non sarà promossa da Monti Riffeser negli Stati Uniti d’America, o
        in ogni altro territorio sottoposto alla giurisdizione degli Stati Uniti d’America
        (collettivamente, gli “<strong>Stati Uniti d’America</strong>”) – ovvero non è stata e non
        sarà rivolta a una U.S. Person (come definita ai sensi del U.S. Securities Act del 1933 e
        successive modificazioni) – né è stata e sarà promossa in Australia, Canada, Giappone,
        nonché in altri paesi diversi dall’Italia in cui la diffusione non sia consentita in assenza
        di autorizzazione da parte delle competenti autorità (collettivamente gli “
        <strong>Altri Paesi</strong>”), né utilizzando i servizi postali né alcun altro strumento di
        comunicazione o commercio internazionale
        <br />
        (ivi inclusi, a titolo esemplificativo, la rete postale, il fax, il telex, la posta
        elettronica, il telefono e internet) degli Stati Uniti d’America, Australia, Canada,
        Giappone o degli Altri Paesi, né qualsivoglia struttura di alcuno degli intermediari
        finanziari degli Stati Uniti d’America, Australia, Canada,
        <br />
        Giappone o degli Altri Paesi, né attraverso alcuno dei mercati regolamentati nazionali degli
        Stati Uniti d’America, Australia, Canada, Giappone o degli Altri Paesi.
      </p>
      <p>
        Copia del documento di Offerta, o di porzioni dello stesso, così come copia di qualsiasi
        successivo documento che l’Offerente emetterà in relazione all’Offerta, non sono e non
        dovranno essere inviati, né in qualsiasi modo trasmessi, o comunque distribuiti,
        direttamente o indirettamente, negli
        <br />
        Stati Uniti d’America, Australia, Canada, Giappone o Altri Paesi.
      </p>
      <p>
        Chiunque riceva i suddetti documenti non dovrà distribuirli, inviarli o spedirli (né a mezzo
        posta né attraverso alcun altro mezzo o strumento di comunicazione o commercio) negli Stati
        Uniti d’America, Australia, Canada, Giappone o Altri Paesi. Pertanto, l’adesione all’Offerta
        da parte di soggetti residenti in paesi diversi dall’Italia può essere soggetta a specifici
        obblighi o restrizioni previsti da disposizioni di legge o regolamentari. È esclusiva
        responsabilità dei destinatari dell’Offerta conformarsi a tali norme e, pertanto, prima di
        aderire all’Offerta, verificarne l’esistenza e l’applicabilità, anche rivolgendosi a propri
        consulenti e conformandosi alle disposizioni stesse prima dell’adesione all’Offerta.
      </p>
      <p>
        Non saranno accettate dall’Offerente eventuali adesioni all’Offerta conseguenti ad attività
        di sollecitazione poste in essere in violazione delle limitazioni qui descritte.
      </p>
      <p>
        Il documento di Offerta, la documentazione e le informazioni riportate nella presente
        sezione del sito internet non costituiscono e non potranno essere interpretate quali offerta
        di strumenti finanziari rivolta a soggetti residenti negli Stati Uniti d’America, Canada,
        Giappone, Australia o negli Altri Paesi. Chiunque venga in possesso dei summenzionati
        documenti dovrà astenersi dal trasmetterli o distribuirli, direttamente o indirettamente,
        dagli e/o negli Stati Uniti d’America, Australia, Canada, Giappone o negli Altri Paesi,
        ovvero a U.S. Person.
      </p>
      <p>
        <strong>
          Chiunque intenda prendere visione del documento di Offerta e degli altri documenti
          presenti in questa sezione del sito deve leggere attentamente e avere piena consapevolezza
          di quanto sopra riportato. Premendo il pulsante “accetto”, si dichiara e garantisce di
          aver compreso integralmente e accettato di rispettare tutte le limitazioni sopra indicate
          e si dichiara di non essere una U.S. Person e di non trovarsi fisicamente né di essere
          residente negli Stati Uniti d’America, Australia, Canada, Giappone o in uno degli Altri
          Paesi.Welcome to WordPress. This is your first post. Edit or delete it, then start
          writing!
        </strong>
      </p>
    </div>
    <div className="en" style={{ width: '50%', padding: 15, background: '#f6f6f6' }}>
      <p>
        With reference to the voluntary tender offer (offerta pubblica di acquisto volontaria) (the
        “<strong>Offer</strong>”) launched by Monti Riffeser S.r.l. (“
        <strong>Monti Riffeser</strong>” or the “<strong>Offeror</strong>”) on all the ordinary
        shares of Monrif S.p.A. (respectively the “Shares” and the “Issuer”), not already owned by
        the Offeror and by any persons acting in concert, as well as, the treasury shares owned by
        the Issuer, it is highlighted that the Offer is addressed to all the owners of the Shares on
        equal conditions, but it is exclusively promoted on the Italian market, as the Shares are
        traded only on Euronext Milan organized and
        <br />
        managed by Borsa Italiana S.p.A.
      </p>
      <p>
        The Offer was not and it shall not be promoted by Monti Riffeser in the United States of
        America, or in any other country subject to the jurisdiction of the United States of America
        (collectively the “<strong>United States of America</strong>”) – it was not and it shall not
        be addressed to a U.S. Person (as defined by the U.S. Securities Act dated 1933, as
        subsequently amended) – and it was not and it shall not be promoted in Australia, Canada,
        Japan and any country other than Italy in which this is
        <br />
        not allowed without specific authorizations of the competent authorities (collectively the “
        <strong>Other Countries</strong>”), either by using the postal services or other
        communication or international commerce means (including therein, for example, the postal
        network, the fax, the telex, the e-mailing, the telephone and the internet) of the United
        States of America, Australia, Canada, Japan or of the Other Countries, or whatever financial
        intermediaries structure of the United States of America,
        <br />
        Australia, Canada, Japan or of the Other Countries, or through any of the national regulated
        markets of the United States of America, Australia, Canada, Japan or of the Other Countries.
      </p>
      <p>
        Copy of the Offer documentation, or part of it, as well as copy of any further document that
        the Offeror will issue in relation to the Offer, are not and shall not be sent, or in any
        way transmitted, or distributed, directly or indirectly, in the United States of America,
        Australia, Canada, Japan or Other Countries. Anyone who receives the above-mentioned
        documentation shall not distribute, send or forward them (either by using the postal
        services or other communication or commerce means) in the United States of America,
        Australia, Canada, Japan or Other Countries.
      </p>
      <p>
        Therefore, acceptance to the Offer by parties residing in countries other than Italy may be
        subject to specific obligations or restrictions as provided for by the applicable laws and
        regulations. It is the sole responsibility of the addressees of this Offer to verify the
        existence and applicability of any such provisions, using appropriate consultants and
        intermediaries, and to comply with such laws and regulations prior to accepting the Offer.
      </p>
      <p>
        Acceptances of the Offer caused by solicitation activities carried out in violation of the
        limitations described herein shall not be accepted by the Offeror.
      </p>
      <p>
        <br />
        The Offer document, the documents and information contained in this section of the website
        do not constitute and shall not be construed as an offer of financial instruments addressed
        to residents in the United States of America, Canada, Japan, Australia or in the Other
        Countries. Anyone who is in possession of the above-mentioned documents shall abstain from
        distributing or transmitting, either directly or indirectly, in and/or from the United
        States of America, Australia, Canada, Japan and in or from the Other Countries or to a U.S.
        Person.
      </p>
      <p>
        <strong>
          Anyone who intends to examine the Offer document and the other documents contained in this
          section of the website must read carefully and be fully aware of the information reported
          above. By clicking on the button “Accept”, the investor represents and warrants that (s)he
          has fully understood and accepted the limitations reported above and also that (s)he is
          not a U.S. Person nor (s)he is or resides in the United States of America, Canada, Japan,
          Australia and any of the Other Countries.
        </strong>
      </p>
    </div>
  </div>
);

export default DownloadDisclaimerTipo2;
