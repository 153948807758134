import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { breakpoints } from 'lib/constants';
import { pxToRem } from 'lib/utils';

const StyledPageBody = styled.div`
  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    display: flex;
    margin-top: ${pxToRem('60px')};
  }
`;

const PageBody = ({ children }) => <StyledPageBody>{children}</StyledPageBody>;

PageBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageBody;
