import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from 'components/BlockContent';
import ArticleHeading from 'components/ArticleHeading';
import ContextNav from 'components/ContextNav';
import FluidContent from 'components/FluidContent';
import PageHeader from 'layout/PageHeader';
import PageBody from 'layout/PageBody';
import Layout from 'layout/Layout';
import Seo from 'components/Seo';

export const PoligraficiContext = React.createContext(false);

export const query = graphql`
  query PostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      title
      url {
        current
      }
      isPoligrafici
      excerpt
      _rawBody(resolveReferences: { maxDepth: 10 })
      metapageResolved {
        title
        url {
          current
        }
        posts {
          title
          id
          url {
            current
          }
        }
      }
    }
  }
`;

const PostTemplate = (props) => {
  const { data } = props;
  const post = data && data.post;
  const metapageResolved = post.metapageResolved || [];
  const metaPageTitle = (metapageResolved.length > 0 && metapageResolved[0].title) || '';
  return (
    <PoligraficiContext.Provider value={post.isPoligrafici}>
      <Layout uri={props.path}>
        <Seo title={post.title} description={post.excerpt} />
        <PageHeader headingType="h2" title={metaPageTitle} />
        <PageBody>
          {metapageResolved.length > 0 && metapageResolved[0].posts && (
            <ContextNav
              metapageSlug={metapageResolved[0].url.current}
              data={metapageResolved[0].posts}
              uri={props.path}
            />
          )}
          <FluidContent>
            <ArticleHeading headingType="h1">{post.title}</ArticleHeading>
            {post._rawBody && <BlockContent blocks={post._rawBody} />}
          </FluidContent>
        </PageBody>
      </Layout>
    </PoligraficiContext.Provider>
  );
};

export default PostTemplate;
