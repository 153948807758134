import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { pxToRem } from 'lib/utils';
import { breakpoints } from 'lib/constants';

const StyledHeading = styled.h2`
  margin: 0;
  font-weight: 700;
  line-height: 1.3;
  text-transform: uppercase;

  @media screen and (max-width: ${pxToRem(breakpoints.large)}) {
    font-size: ${pxToRem('20px')};
    letter-spacing: ${pxToRem('2px')};
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    font-size: ${pxToRem('24px')};
    letter-spacing: ${pxToRem('3px')};
  }
`;

const ArticleHeading = ({ children, headingType }) => {
  return <StyledHeading as={headingType}>{children}</StyledHeading>;
};

ArticleHeading.propTypes = {
  children: PropTypes.node.isRequired,
  headingType: PropTypes.string,
};

export default ArticleHeading;
