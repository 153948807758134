import { hexToRgb } from 'lib/utils';

export const addOutline = () => `
  //outline: 1px dashed rgba(${hexToRgb('#c1c1c1')}, 0.3);
  outline: none;
`;
export const resetButton = () => `
  width: auto;
  margin: 0;
  padding: 0;
  font-family: inherit;
  line-height: normal;
  border: none;
  overflow: visible;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
`;
export const resetSelect = () => `
  display: block;
  width: 100%;
  max-width: 100%;
  font-family: inherit;
  line-height: normal;
  background: transparent;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
`;
export const visuallyHidden = () => `
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
`;
