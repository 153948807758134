const onConsentHandler = () => {
  Array.isArray(window.requireConsent) &&
    window.requireConsent.forEach((cmd) => typeof cmd === 'function' && cmd());
  window.requireConsent = {
    push: function (cmd) {
      if (typeof cmd === 'function') cmd();
    },
  };
};

const openPrivacyPreferences = () => {
  window.___pubtech_open_cmp();
};

const injectLiveRampSdk = () => {
  const a = window.document.createElement('script');
  a.type = 'text/javascript';
  a.async = true;
  a.setAttribute('data-cfasync', false);
  a.src = `//cmp.pubtech.ai/124/pubtech-cmp-v2.js`;
  const b = window.document.getElementsByTagName('script')[0];
  (b.parentNode || { insertBefore: () => '' }).insertBefore(a, b);
};

const init = () => {
  /*
   * Add a global requireConsent queue that will be executed as soon as the user provide her consent.
   */
  if (typeof window !== 'undefined') {
    window.onConsentHandler = onConsentHandler;
    window.requireConsent = window.requireConsent || [];
    injectLiveRampSdk();

    // --- PUBTECH CMP -------------------------------

    // eslint-disable-next-line camelcase
    var __pub_tech_cmp_on_consent_queue = __pub_tech_cmp_on_consent_queue || [];
    __pub_tech_cmp_on_consent_queue.push(
      (consentStrings, consentObject, publisherConsentObject) => {
        let purposeRequired;
        const purposeIdRequired = [1, 2, 7, 9, 10];
        purposeIdRequired.forEach((id) => {
          purposeRequired =
            consentObject.purposeConsents.has(id) === true && purposeRequired !== false;
        });

        if (purposeRequired) {
          // eslint-disable-next-line no-undef
          localStorage.setItem('Monrif CMP', 'true');
        }
      },
    );
    // --- End PUBTECH CMP ----------------------------
  }
};

export { onConsentHandler, init, openPrivacyPreferences };
