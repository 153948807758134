import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { pxToRem } from 'lib/utils';

const StyledTable = styled.table`
  width: 100%;
  font-size: ${pxToRem('13px')};
  border-collapse: collapse;
`;

const StyledTr = styled.tr`
  body.dark & {
    color: ${(props) => props.theme.dark.colors.textOff};
  }

  body.light & {
    color: ${(props) => props.theme.light.colors.textOff};
  }

  &:first-child {
    td {
      font-weight: 700;
      border-top: none;

      body.dark & {
        color: ${(props) => props.theme.dark.colors.text};
      }

      body.light & {
        color: ${(props) => props.theme.light.colors.text};
      }
    }
  }

  &:last-child {
    td {
      border-bottom: none;
    }
  }
`;

const StyledTd = styled.td`
  padding: ${pxToRem('10px')} ${pxToRem('8px')};
  border-top: 1px solid;
  border-bottom: 1px solid;
  color: inherit;
  vertical-align: top;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  body.dark & {
    border-top-color: ${(props) => props.theme.dark.colors.textOff};
    border-bottom-color: ${(props) => props.theme.dark.colors.textOff};
  }

  body.light & {
    border-top-color: ${(props) => props.theme.light.colors.textOff};
    border-bottom-color: ${(props) => props.theme.light.colors.textOff};
  }
`;

const Table = ({ data }) => (
  <StyledTable>
    <tbody>
      {data.rows.map((row) => (
        <StyledTr key={row._key}>
          {row.cells.map((cell, index) => (
            <StyledTd key={index}>{cell}</StyledTd>
          ))}
        </StyledTr>
      ))}
    </tbody>
  </StyledTable>
);

Table.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        cells: PropTypes.array.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default Table;
