import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import ConditionalWrapper from 'components/ConditionalWrapper';
import logoMonrif from 'images/logo-monrif.svg';
import logoPoligrafici from 'images/logo-poligrafici-printing-2.svg';
import { useSiteMetadata } from 'hooks';
import { sizes } from 'lib/constants';
import { pxToRem } from 'lib/utils';
import { PoligraficiContext } from 'templates/PostTemplate';

const StyledLogo = styled.div`
  padding: ${pxToRem('17px')} ${pxToRem(sizes.edgeSpacing)};
`;

const Logo = ({ uri }) => {
  const isPoligrafici = useContext(PoligraficiContext);
  const homeUrl = '/';
  const { title } = useSiteMetadata(isPoligrafici);

  const StyledImg = styled(isPoligrafici ? logoPoligrafici : logoMonrif)`
    display: block;
    height: ${pxToRem('26px')};
    width: auto;

    path {
      transition: fill 0.5s ease;

      body.dark & {
        fill: ${(props) => props.theme.dark.colors.text};
      }

      body.light & {
        fill: ${(props) => props.theme.light.colors.text};
      }
    }
  `;

  return (
    <StyledLogo>
      <ConditionalWrapper
        condition={uri !== '/'}
        wrapper={(children) => (
          <Link to={homeUrl} title={title}>
            {children}
          </Link>
        )}
      >
        <StyledImg title={title} />
      </ConditionalWrapper>
    </StyledLogo>
  );
};

export default Logo;
