import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql, Link, StaticQuery } from 'gatsby';

import { breakpoints, sizes } from 'lib/constants';
import { addOutline } from 'lib/mixins';
import { pxToRem, getLinkThemeColor } from 'lib/utils';

export const query = graphql`
  query FirstLevelMenuMonrif {
    allSanityMetapage(sort: { order: ASC, fields: order }, filter: { isMonrif: { eq: true } }) {
      edges {
        node {
          id
          title
          url {
            current
          }
          posts {
            url {
              current
            }
          }
        }
      }
    }
  }
`;

const StyledNavList = styled.ul`
  margin: 0;
  padding: 0;
  font-size: ${pxToRem('14px')};
  font-weight: 700;
  list-style: none;

  @media screen and (max-width: ${pxToRem(breakpoints.large)}) {
    overflow: hidden;
    transition: background-color 0.5s ease, opacity 0.5s ease;

    body.dark & {
      background-color: ${(props) => props.theme.dark.colors.background};
    }

    body.light & {
      background-color: ${(props) => props.theme.light.colors.background};
    }

    ${({ open }) =>
      open
        ? `
      position: fixed;
      top: ${pxToRem(sizes.headerHeight)};
      left: 0;
      right: 0;
      width: 100vw;
      height: calc(100vh - ${pxToRem(sizes.headerHeight)});
      padding-top: ${pxToRem('20px')};
      opacity: 1;
      visibility: visible;
    `
        : `
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
    `}
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    display: flex;
    justify-content: center;
  }
`;

const StyledLi = styled.li`
  padding: ${pxToRem('10px')} ${pxToRem(sizes.edgeSpacing)};

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    padding: ${pxToRem('10px')};
  }
`;

const MenuLink = ({ className, children, ...props }) => (
  <Link getProps={isActive(className)} {...props}>
    {children}
  </Link>
);

const StyledLink = styled(MenuLink)`
  padding: ${pxToRem('10px')} ${pxToRem(sizes.edgeSpacing)};
  text-decoration: none;

  body.dark & {
    color: ${(props) =>
      props.$isSelected ? props.theme.dark.colors.text : props.theme.dark.colors.textOff};

    &.isSelected {
      color: ${(props) => props.theme.dark.colors.text};
    }
  }

  body.light & {
    color: ${(props) =>
      props.$isSelected ? props.theme.light.colors.text : props.theme.light.colors.textOff};

    &.isSelected {
      color: ${(props) => props.theme.light.colors.text};
    }
  }

  &:hover {
    body.dark &:not(.isSelected) {
      color: ${(props) => getLinkThemeColor(props.isPoligrafici)};
    }

    body.light &:not(.isSelected) {
      color: ${(props) => getLinkThemeColor(props.isPoligrafici)};
    }
  }

  &:focus {
    ${addOutline()}
  }

  &.isSelected {
    cursor: default;
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    padding: ${pxToRem('10px')};
  }
`;

const isActive = (className) => ({ isCurrent, isPartiallyCurrent, location, href }) => {
  const activeClassName = { className: `${className} isSelected` };
  const firstLevelMenu = href.split('/')[1];

  if (isPartiallyCurrent || isCurrent || location.pathname.startsWith(`/${firstLevelMenu}/`)) {
    return activeClassName;
  }

  return { className };
};

const MainMenuMonrif = ({ open, uri, className, ...props }) => {
  const isHidden = !!open;
  const tabIndex = isHidden ? 0 : -1;
  return (
    <StyledNavList aria-hidden={!isHidden} className={className} open={open}>
      <StaticQuery
        query={query}
        render={(data) => {
          const { edges } = data.allSanityMetapage;
          return edges.map((edge) => {
            const { node } = edge;
            const { posts = [] } = node;
            const firstLevelMenu = node && node.url.current;
            const secondLevelUrl = (posts[0] && posts[0].url.current) || '#';
            const title = node.title;
            const url = firstLevelMenu === '/home' ? '/' : `/${firstLevelMenu}/${secondLevelUrl}/`;
            return (
              <StyledLi key={node.id}>
                <StyledLink tabIndex={tabIndex} to={url} isPoligrafici={false}>
                  {title}
                </StyledLink>
              </StyledLi>
            );
          });
        }}
      />
    </StyledNavList>
  );
};

MainMenuMonrif.propTypes = {
  open: PropTypes.bool,
};

export default MainMenuMonrif;
