import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Colophon from 'components/Colophon';
import MainMenuMonrif from 'components/MainMenuMonrif';
import MainMenuPoligrafici from 'components/MainMenuPoligrafici';
import { pxToRem } from 'lib/utils';
import { PoligraficiContext } from 'templates/PostTemplate';

const StyledFooter = styled.footer`
  padding: ${pxToRem('20px')} 0 ${pxToRem('30px')} 0;
  text-align: center;
  transition: background-color 0.5s ease;

  body.dark & {
    background-color: ${(props) => props.theme.dark.colors.background};
  }

  body.light & {
    background-color: ${(props) => props.theme.light.colors.background};
  }
`;

const Footer = ({ uri }) => {
  const isPoligrafici = useContext(PoligraficiContext);

  return (
    <StyledFooter>
      {isPoligrafici ? <MainMenuPoligrafici uri={uri} /> : <MainMenuMonrif uri={uri} />}
      <Colophon />
    </StyledFooter>
  );
};

Footer.propTypes = {
  uri: PropTypes.string.isRequired,
};

export default Footer;
