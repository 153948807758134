import React from 'react';

const DownloadDisclaimer = () => (
  <div>
    <p>
      Prima di accedere ai contenuti di questa sezione del sito internet si prega di leggere
      attentamente e accettare l’informativa di seguito riportata
    </p>
    <p>
      La documentazione e le informazioni riportate nella presente sezione del sito internet sono
      accessibili esclusivamente da soggetti che non siano domiciliati o che non si trovino negli
      Stati Uniti d’America, in Australia, Canada, Giappone, nonché in qualsiasi altro Paese diverso
      dall’Italia in cui sia necessaria un’autorizzazione da parte delle competenti autorità o altri
      adempimenti (tali Paesi, inclusi gli Stati Uniti d’America, Canada, Giappone e Australia,
      collettivamente gli “Altri Paesi”).
    </p>
    <p>
      La documentazione e le informazioni riportate nella presente sezione del sito internet non
      sono e non dovranno essere inviati, né in qualsiasi modo trasmessi, o comunque distribuiti,
      direttamente o indirettamente, negli Stati Uniti d’America, in Australia, Canada, Giappone e
      negli Altri Paesi.
    </p>
    <p>
      La documentazione e le informazioni riportate nella presente sezione del sito internet non
      sono e non dovranno essere inviati, né in qualsiasi modo trasmessi, o comunque distribuiti,
      direttamente o indirettamente, a soggetti residenti o fisicamente presenti negli Stati Uniti
      d’America, in Australia, Canada, Giappone e non costituiscono e non potranno essere
      interpretati quali offerta di acquisto ovvero sollecitazione di un’offerta di vendita di
      strumenti finanziari rivolta ad U.S. Persons – come definite ai sensi dello U.S. Securities
      Act del 1933 e successive modificazioni – o a soggetti residenti negli Altri Paesi. Le azioni
      di Monrif S.p.A. cui si fa riferimento nella presente sezione del sito internet non sono state
      e non saranno registrate ai sensi dello U.S. Securities Act del 1933 e non possono essere
      offerte o vendute negli Stati Uniti di America in assenza di registrazione o di una esenzione
      dal relativo obbligo di registrazione.
    </p>
    <p>
      Al fine di accedere alle informazioni e ai documenti di cui alla presente sezione del sito
      internet, si dichiara e garantisce sotto la propria responsabilità personale di non essere una
      U.S. Person e di non trovarsi fisicamente né di essere residente negli Stati Uniti d’America,
      Australia, Canada, Giappone o in uno degli Altri Paesi e di aver compreso integralmente ed
      accettato di rispettare tutte le limitazioni sopra indicate.
    </p>
  </div>
);

export default DownloadDisclaimer;
