import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Component from 'components/Component';

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ComponentList = ({ data }) => (
  <StyledList className="wysiwyg">
    {data.map((el) => (
      <Component {...el} key={el._key} />
    ))}
  </StyledList>
);

ComponentList.propTypes = {
  data: PropTypes.array,
};

export default ComponentList;
