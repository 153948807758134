import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Hamburger from 'layout/Hamburger';
import { sizes } from 'lib/constants';
import { addOutline, resetButton } from 'lib/mixins';
import { pxToRem } from 'lib/utils';

const StyledButton = styled.button`
  ${resetButton()};
  position: relative;
  height: ${pxToRem(sizes.hamburgerWidth)};
  width: ${pxToRem(sizes.hamburgerWidth)};
  padding: ${pxToRem('8px')};
  background: transparent;
  box-sizing: content-box;

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
  }

  &:focus {
    ${addOutline()}
  }
`;

const NavToggler = ({ open, setOpen, ...props }) => {
  const isExpanded = open;

  return (
    <StyledButton
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      className={props.className}
      onClick={() => setOpen(!open)}
      {...props}
    >
      <Hamburger open={open} />
    </StyledButton>
  );
};

NavToggler.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default NavToggler;
