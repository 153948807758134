export const breakpoints = {
  small: '480px', // min-width: 480px	Mobile-landscape (and larger)
  medium: '768px', // min-width: 768px	Tablet-portrait (and larger)
  large: '992px', // min-width: 992px	Tablet-landscape (and larger)
  xlarge: '1200px', // min-width: 1200px	Laptops (and langer)
};
export const fonts = {
  serif: 'later',
  sans: '"Helvetica Neue", Helvetica, Arial, sans-serif',
};
export const layers = {
  header: 900,
  modal: 910,
};
export const sizes = {
  edgeSpacing: '20px',
  hamburgerWidth: '20px',
  headerHeight: '60px',
  phi: 1.61803398875,
};
