import React from 'react';
import BaseBlockContent from '@sanity/block-content-to-react';
import { breakpoints } from 'lib/constants';

import Attachment from 'components/Attachment';
import AttachmentList from 'components/AttachmentList';
import Figure from 'components/Figure';
import SectionHeading from 'components/SectionHeading';
import Table from 'components/Table';
import ComponentList from 'components/ComponentList';
import styled from 'styled-components';
import { pxToRem } from 'lib/utils';

const list = (props) => <ul className="wysiwyg">{props.children}</ul>;

const targetBlank = ({ mark, children }) =>
  mark.blank ? (
    <a href={mark.href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <a href={mark.href}>{children}</a>
  );

const marks = { link: targetBlank };

const types = {
  table(props) {
    return <Table data={props.node} />;
  },
  block(props) {
    switch (props.node.style) {
      case 'h3':
        return <SectionHeading headingType="h3">{props.children}</SectionHeading>;

      case 'h4':
        return <SectionHeading headingType="h4">{props.children}</SectionHeading>;

      case 'h5':
        return <SectionHeading headingType="h5">{props.children}</SectionHeading>;

      case 'h6':
        return <SectionHeading headingType="h6">{props.children}</SectionHeading>;

      case 'blockquote':
        return <blockquote>{props.children}</blockquote>;

      default:
        return <p>{props.children}</p>;
    }
  },
  figure(props) {
    return <Figure {...props.node} />;
  },
  attachment(props) {
    return <Attachment {...props.node} />;
  },
  attachmentList(props) {
    return <AttachmentList data={props.node.attachmentList} />;
  },
  componentList(props) {
    return <ComponentList data={props.node.components} />;
  },
  simpleParagraph(props) {
    return <SimpleParagraph data={props.node.content} />;
  },
};

const serializers = {
  list,
  marks,
  types,
};

const StyledSimpleParagraph = styled.div`
  flex-basis: 100%;

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    flex-basis: 50%;
    padding-right: ${pxToRem('40px')};
  }
`;

const SimpleParagraph = ({ data }) => {
  const { title = '', content = [] } = data;

  return (
    <StyledSimpleParagraph>
      <SectionHeading headingType="h4">{title}</SectionHeading>
      {content.map((block, i) => (
        <BaseBlockContent blocks={block} key={i} serializers={serializers} />
      ))}
    </StyledSimpleParagraph>
  );
};

export default SimpleParagraph;
