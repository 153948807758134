import React from 'react';
import { buildImageObj } from 'lib/helpers';
import { imageUrlFor } from 'lib/imageUrl';

function Figure(props) {
  return (
    <figure>
      {props.asset && (
        <img src={imageUrlFor(buildImageObj(props)).width(900).url()} alt={props.alt} />
      )}
      <figcaption>{props.caption}</figcaption>
    </figure>
  );
}

export default Figure;
