import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';
import { MdKeyboardArrowDown } from 'react-icons/md';

import Attachment from 'components/Attachment';
import { addOutline, resetButton } from 'lib/mixins';
import { hexToRgb, pxToRem, svgComponentToDataURI } from 'lib/utils';

const StyledList = styled.ul`
  padding: 0;
  font-size: ${pxToRem('14px')};
  list-style: none;
  border-top: 1px solid;

  body.dark & {
    border-top-color: ${(props) => props.theme.dark.colors.separator};
  }

  body.light & {
    border-top-color: ${(props) => props.theme.light.colors.separator};
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ToggleArrow = ({ color }) => (
  <IconContext.Provider value={{ color }}>
    <MdKeyboardArrowDown />
  </IconContext.Provider>
);

const StyledViewMore = styled.button`
  ${resetButton()};
  width: 100%;
  padding: ${pxToRem('10px')};
  cursor: pointer;
  text-align: center;
  background: transparent;
  transition: background 0.5s ease;

  &::after {
    display: inline-block;
    width: ${pxToRem('15px')};
    height: ${pxToRem('15px')};
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: auto ${pxToRem('18px')};
    content: '';

    body.dark & {
      ${(props) =>
        `background-image: url("${svgComponentToDataURI(
          <ToggleArrow color={props.theme.dark.colors.text} />,
        )}")`};
    }

    body.light & {
      ${(props) =>
        `background-image: url("${svgComponentToDataURI(
          <ToggleArrow color={props.theme.light.colors.text} />,
        )}")`};
    }
  }

  &:hover {
    body.dark & {
      background: rgba(${(props) => hexToRgb(props.theme.dark.colors.separator)}, 0.3);
    }

    body.light & {
      background: rgba(${(props) => hexToRgb(props.theme.light.colors.separator)}, 0.1);
    }
  }

  &:focus {
    ${addOutline()}
  }

  &.hidden {
    display: none;
  }
`;

const StyledAttachment = styled(Attachment)`
  margin-top: 0;
  border-top: 0;
`;

const AttachmentList = ({ data }) => {
  const maxNumberOfItemInVisibleList = 4;
  const attachmentList = data || [];
  const [closed, setClosed] = useState(attachmentList.length > maxNumberOfItemInVisibleList);
  const viewMore = (
    <StyledViewMore onClick={() => setClosed(!closed)} title="visualizza altri allegati" />
  );
  const isShowed = (i) => !(closed && i >= maxNumberOfItemInVisibleList);

  return (
    <>
      <StyledList>
        {attachmentList.map(
          (itemData, i) =>
            isShowed(i) &&
            itemData.file && (
              <li key={itemData.file.asset.url}>
                <StyledAttachment {...itemData} />
              </li>
            ),
        )}
      </StyledList>
      {closed && viewMore}
    </>
  );
};

AttachmentList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default AttachmentList;
