import React, { useContext } from 'react';
import styled from 'styled-components';

import ToggleTheme from 'components/ToggleTheme';
import { PoligraficiContext } from 'templates/PostTemplate';
import { useSiteMetadata } from 'hooks';
import { pxToRem } from 'lib/utils';
import { openPrivacyPreferences } from '../components/LiveRamp/LiveRampManager';

const StyledColophon = styled.p`
  margin-bottom: 0;
  font-size: ${pxToRem('12px')};
  transition: color 0.5s ease;

  body.dark & {
    color: ${(props) => props.theme.dark.colors.text};
  }

  body.light & {
    color: ${(props) => props.theme.light.colors.text};
  }
`;

const StyledToggleThemeWrapper = styled.span`
  display: block;
  min-height: ${pxToRem('20px')};
`;

const StyledLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Colophon = () => {
  const isPoligrafici = useContext(PoligraficiContext);
  const { legalTitle, vat, taxCode, shareCapital } = useSiteMetadata(isPoligrafici);

  return (
    <StyledColophon>
      &copy; 2020 {legalTitle} all right reserved
      <br /> P. IVA {vat} &ndash; C.F. {taxCode}
      <br /> Capitale Sociale {shareCapital}
      <StyledToggleThemeWrapper>
        <StyledLink
          onClick={(e) => {
            openPrivacyPreferences();
            e.preventDefault();
          }}
          href="#"
        >
          Impostazioni privacy
        </StyledLink>{' '}
        &ndash;{' '}
        <StyledLink href="/privacy-policy/premessa/" title="Privacy Policy">
          Privacy Policy
        </StyledLink>{' '}
        &ndash; <ToggleTheme />
      </StyledToggleThemeWrapper>
    </StyledColophon>
  );
};

export default Colophon;
