import { normalize } from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';

import { fonts, sizes } from 'lib/constants';
import { pxToRem, getLinkThemeColor } from 'lib/utils';

const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  ${normalize}

  ::selection {
    color: ${(props) => props.theme.dark.colors.text};
    background: ${(props) => props.theme.dark.colors.link};
  }

  body {
    font-family: ${fonts.sans};
    font-size: 100%;
    font-weight: 400;
    line-height: ${sizes.phi};
    -webkit-font-smoothing: antialiased;
    transition: background-color 0.5s ease, color 0.5s ease;
  }

  body.dark {
    color: ${(props) => props.theme.dark.colors.text};
    background-color: ${(props) => props.theme.dark.colors.background};

  }

  body.light {
    color: ${(props) => props.theme.light.colors.text};
    background-color: ${(props) => props.theme.light.colors.background};
  }

  a {
    transition: color 0.5s ease;

    body.dark & {
      color: ${(props) => getLinkThemeColor(props.isPoligrafici)}
    }

    body.light & {
      color: ${(props) => getLinkThemeColor(props.isPoligrafici)}
    }
  }

  a.poligrafici {
    body.dark & {
      color: ${(props) => props.theme.dark.colors.poligraficiGreen}
    }

    body.light & {
      color: ${(props) => props.theme.dark.colors.poligraficiGreen}
    }
  }

  dl {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    overflow: visible;
  }

  dl dt {
    font-weight: 700;
    flex: 0 0 30%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  dl dd {
    flex:0 0 70%;
    margin-left: auto;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  p.wysiwyg, ul.wysiwyg, ol.wysiwyg {
    max-width: ${pxToRem('640px')};
  }

  ul.wysiwyg li, ol.wysiwyg li {
    margin-top: ${pxToRem('14px')};
    margin-bottom: ${pxToRem('14px')};
  }

  .homeList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: ${pxToRem('1000px')};
    margin: 0 auto;
    padding: 0;
    font-size: ${pxToRem('14px')};
    list-style: none;
  }

  .homeList section,
  .homeList header,
  .homeList ul {
    flex-basis: 100%;
    padding: 0;
  }
`;

export default GlobalStyles;
