import { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = (isPoligrafici) => {
  const { monrif, poligraficiPrinting } = useStaticQuery(
    graphql`
      query {
        monrif: sanityInfo(id: { eq: "-211076ce-c181-5fb2-937e-b696e79214b8" }) {
          legalTitle
          title
          author
          description
          vat
          taxCode
          shareCapital
        }

        poligraficiPrinting: sanityInfo(id: { eq: "-07b68151-87e3-52f2-a3c8-570557ad9d7e" }) {
          legalTitle
          title
          author
          description
          vat
          taxCode
          shareCapital
        }
      }
    `,
  );

  return isPoligrafici ? poligraficiPrinting : monrif;
};

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
