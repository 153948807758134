import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { sizes } from 'lib/constants';
import { pxToRem } from 'lib/utils';

const StyledMain = styled.main`
  flex: 1;
  transition: background-color 0.5s ease;

  body.dark & {
    background-color: ${(props) => props.theme.dark.colors.background2};
  }

  body.light & {
    background-color: ${(props) => props.theme.light.colors.background2};
  }
`;

const StyledInner = styled.div`
  margin: 0 auto;
  padding-top: ${pxToRem(sizes.headerHeight)};
  padding-bottom: ${pxToRem(sizes.headerHeight)};

  @media screen and (max-width: ${pxToRem('1024px')}) {
    padding-right: ${pxToRem(sizes.edgeSpacing)};
    padding-left: ${pxToRem(sizes.edgeSpacing)};
  }

  @media screen and (min-width: ${pxToRem('1024px')}) {
    max-width: ${pxToRem('960px')};
  }

  @media screen and (min-width: ${pxToRem('1216px')}) {
    max-width: ${pxToRem('1152px')};
  }

  @media screen and (min-width: ${pxToRem('1408px')}) {
    max-width: ${pxToRem('1344px')};
  }
`;

const Main = ({ children }) => (
  <StyledMain>
    <StyledInner>{children}</StyledInner>
  </StyledMain>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
