import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';
import { MdKeyboardArrowDown } from 'react-icons/md';

import SectionHeading from 'components/SectionHeading';
import { pxToRem, svgComponentToDataURI } from 'lib/utils';

const ToggleArrow = ({ color }) => (
  <IconContext.Provider value={{ color }}>
    <MdKeyboardArrowDown />
  </IconContext.Provider>
);

const StyledHeader = styled.header`
  position: relative;
  display: inline-block;
  padding-right: ${pxToRem('25px')};
  cursor: pointer;

  &::after {
    position: absolute;
    bottom: ${pxToRem('2px')};
    right: 0;
    width: ${pxToRem('15px')};
    height: ${pxToRem('15px')};
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto ${pxToRem('18px')};
    content: '';
    transition: transform 0.5s ease;

    body.dark & {
      ${(props) =>
        `background-image: url("${svgComponentToDataURI(
          <ToggleArrow color={props.theme.dark.colors.text} />,
        )}")`};
    }

    body.light & {
      ${(props) =>
        `background-image: url("${svgComponentToDataURI(
          <ToggleArrow color={props.theme.light.colors.text} />,
        )}")`};
    }

    ${(props) =>
      props.open
        ? `
      transform: rotate(-180deg);`
        : `transform: rotate(0);`}
`;

const StyledParagraph = styled.p`
  margin-top: ${pxToRem('5px')};
`;

const StyledSpan = styled.span`
  display: block;
`;

const StyledDescription = styled.span`
  display: block;
  overflow: hidden;
  white-space: pre-line;
  transition: all 0.5s ease;

  body.dark & {
    color: ${(props) => props.theme.dark.colors.textOff};
  }

  body.light & {
    color: ${(props) => props.theme.light.colors.textOff};
  }

  ${(props) =>
    props.open
      ? `
    opacity: 1;
    margin-top: ${pxToRem('15px')};
    visibility: visible;`
      : `opacity: 0;
    max-height: 0;
    visibility: hidden;`}
`;

const Component = ({ component, headline, powers }) => {
  const [open, toggleOpen] = useState(false);

  return (
    <li key={component.id}>
      <StyledHeader onClick={() => toggleOpen(!open)} open={open}>
        <SectionHeading headingType="h5">{component.name}</SectionHeading>
      </StyledHeader>
      <StyledParagraph>
        <StyledSpan>
          <strong>{headline}</strong>
        </StyledSpan>
        {powers && <StyledSpan>{powers}</StyledSpan>}
        <StyledDescription open={open}>{component.description}</StyledDescription>
      </StyledParagraph>
    </li>
  );
};

Component.propTypes = {
  component: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  headline: PropTypes.string,
  powers: PropTypes.string,
};

export default Component;
