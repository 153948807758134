import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import GlobalStyles from 'styles/GlobalStyles';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import Main from 'layout/Main';
import { PoligraficiContext } from 'templates/PostTemplate';
import LiveRamp from '../components/LiveRamp';

const StyledContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

function ee() {
  if (typeof window !== `undefined`) {
    if (/\?mido=1/.test(window.location.search)) {
      window.setInterval(() => {
        document.body.classList.toggle('light');
        document.body.classList.toggle('dark');
      }, 1000);
    }
  }
}

const liverampId = 'ef3f22a6-8c54-474f-a3ea-68e39266d52b';

const Layout = ({ children, uri }) => {
  const isPoligrafici = useContext(PoligraficiContext);
  // EE
  ee();
  return (
    <>
      <GlobalStyles isPoligrafici={isPoligrafici} />
      <LiveRamp liverampId={liverampId}>
        <StyledContainer>
          <Header uri={uri} />
          <Main>{children}</Main>
          <Footer uri={uri} />
        </StyledContainer>
      </LiveRamp>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
